import React from "react";
import styled from "styled-components";
import { colors } from "../../../style";
import burger from "../../../assets/burgermenu.png";

const Wrapper = styled.div`
  padding: 8px 16px;
  margin: 8px;
  border-radius: 8px;
  background: ${colors.color2};
  border: 2px solid ${colors.color3};
  color: #fff;
  font-weight: 600;
  z-index: 10;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;

  img {
    height: auto;
    width: 24px;
    margin-left: 16px;
  }

  &:hover {
    background: ${colors.color5};
  }

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const NavButton = ({ text = "link", handleClick, currentShow }) => {
  return (
    <Wrapper
      onClick={() => (currentShow === 0 ? handleClick(1) : handleClick(0))}
    >
      {text}
      <img src={burger} alt="burger menu" />
    </Wrapper>
  );
};

export default NavButton;
