import React, { useEffect } from "react";

import styled from "styled-components";
import gsap from "gsap";

import nagrada from "../../../assets/nagrada.jpg";

import { PageWrapper, HLine } from "../../../style";

const NagradaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    margin-top: 3rem;
    max-width: 100%;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
`;

const Nagrade = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  return (
    <PageWrapper>
      <div ref={(el) => (myRef = el)}>
        <h2>Nagrade</h2>
        <HLine />

        <h2>Naš profesor, Dr Zoran Ristanović osvojio prvu nagradu</h2>
        <p>
          Sa velikim zadovoljstvom objavljujemo da je naš bivši student, a sada
          profesor na našem institutu, dr Zoran Ristanović, inače jedan od
          vodećih stručnjaka za proizvodnju organske hrane u regionu, osvojio je
          prvu nagradu na takmičenju najboljih proizvodjaca organske hrane u
          Srbiji za 2016. godinu. Za više informacija pogledati donje linkove:
        </p>
        <NagradaWrapper>
          <img src={nagrada} alt="nagrada" />
          <p>
            Saznajte više na linku:
            <br />
            <a href="https://www.nlb.rs/vest/4141/proglaseni-pobednici-petog-nlb-organic-konkursa">
              Pobednici petog nlb organic konkursa
            </a>
          </p>
        </NagradaWrapper>
      </div>
    </PageWrapper>
  );
};

export default Nagrade;
