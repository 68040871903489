import React from "react";
import styled from "styled-components";

import { colors } from "../../../../style";

const Wrapper = styled.div`
  border-radius: 50%;
  background: ${colors.color2};
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const Circle = styled.div`
  border-radius: 50%;
  background: ${colors.color3};
  width: 13px;
  height: 13px;
`;

const Round = ({ filled }) => {
  return <Wrapper>{filled && <Circle />}</Wrapper>;
};

export default Round;
