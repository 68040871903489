import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import { colors, Bold, PageWrapper, HLine } from "../../../style";
import Round from "./upis/Round";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: blue;
    text-decoration: underline;
  }
  p {
    margin-top: 0.1rem;
  }
  h2 {
    margin: 2rem 0;
    font-size: 2rem;
    color: ${colors.color2};
  }
  h2:first-of-type {
    margin: 0;
  }

  input {
    padding: 8px;
    padding-left: 24px;
    margin: 0.4rem 0;
    border: 1px solid ${colors.color3};
    border-radius: 2px;

    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  }

  input::-webkit-input-placeholder {
    opacity: 0.8;
  }
  input:-ms-input-placeholder {
    opacity: 0.8;
  }
  input::placeholder {
    opacity: 0.8;
  }
`;

const FieldWrapper = styled.div`
  display: flex;
  input {
    flex: 1;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const Space = styled.div`
  width: 24px;
`;

const Opcije = styled.div`
  font-size: 0.9rem;
  margin: 1rem 2rem;
  h4 {
    margin-bottom: 1rem;
  }
  p {
    margin: 1rem 0;
  }
`;

const Opcija = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 28px 1fr;
  grid-template-rows: 28px;
  align-items: center;
  margin-top: 0.4rem;

  p {
    margin: 0.2rem 0 0;
  }

  @media only screen and (max-width: 600px) {
    span {
      display: block;
    }
  }
`;

const DatumPrijave = styled.p`
  margin: 16px 8px;
  padding: 32px 0 8px;
`;

const ButtonWrapper = styled.div``;

const Button = styled.div`
  background: ${colors.color3};
  border-radius: 4px;
  padding: 8px 32px;
  margin-right: 16px;
  margin-bottom: 16px;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
`;

const Poslato = styled.div`
  background: #d6ffe1;
  color: #1d7535;
  padding: 0.5rem;
`;

const Upis = () => {
  const date = new Date();
  const formatedDate = `${date.getDate()}.${
    date.getMonth() + 1
  }.${date.getFullYear()}`;
  const initState = {
    name: "",
    lastName: "",
    adresa: "",
    telefon: "",
    eMail: "",
    datumRodjenja: "",
    zavrsena: "",
    placanje: "",
    referenca: "",
    datum: "",
    mailSent: false,
    error: null,
  };
  const [myOpcija, setMyOpcija] = useState(0);
  const [myState, setMyState] = useState({
    ...initState,
  });

  const tempMassage = {
    fname: "institutop",
    subject: "prijava",
    message: "Hey there, this is a message.",
    email: "risticmilan89@gmail.com",
  };
  let sendMassage = "";

  const handleChange = (e) => {
    setMyState({ ...myState, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    let newPlacanje = "";
    if (myOpcija === 0) {
      newPlacanje = "Ceo iznos odjednom";
    } else if (myOpcija === 1) {
      newPlacanje = "U ratama";
    } else if (myOpcija === 2) {
      newPlacanje = "Svaki ispit pojedinacno";
    }
    setMyState({ ...myState, placanje: newPlacanje });
  }, [myOpcija]);

  useEffect(() => {
    setMyState({ ...myState, datum: formatedDate });
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <h2>Upis</h2>
        <HLine />
        <ul>
          <li>
            Upis na fakultet je otvoren tokom <Bold>cele godine</Bold> i vrši se
            popunavanjem <Bold>Prijave za upis</Bold>,
          </li>
          <li>
            slanjem podataka na zvanični e-mail fakuleta ({" "}
            <Bold>cns.school@hotmail.com</Bold> ) ili
          </li>
          <li>
            preuzimanjem prijave u pdf formatu sa{" "}
            <a target="_blank" href="./upis.pdf">
              ovog linka.
            </a>
          </li>
        </ul>

        <h2>Prijava za upis</h2>

        <FieldWrapper>
          <input
            onChange={(ev) => handleChange(ev)}
            id="name"
            type="text"
            placeholder="Ime"
            value={myState.name}
          />
          <Space />
          <input
            onChange={handleChange}
            id="lastName"
            type="text"
            placeholder="Prezime"
            value={myState.lastName}
          />
        </FieldWrapper>

        <input
          onChange={handleChange}
          id="adresa"
          type="text"
          placeholder="Kućna adresa"
          value={myState.adresa}
        />

        <FieldWrapper>
          <input
            onChange={handleChange}
            id="telefon"
            type="text"
            placeholder="Telefon"
            value={myState.telefon}
          />
          <Space />
          <input
            onChange={handleChange}
            id="eMail"
            type="text"
            placeholder="E-mail"
            value={myState.eMail}
          />
        </FieldWrapper>

        <input
          onChange={handleChange}
          id="datumRodjenja"
          type="text"
          placeholder="Datum rodjenja"
          value={myState.datumRodjenja}
        />

        <input
          onChange={handleChange}
          id="zavrsena"
          type="text"
          placeholder="Završena srednja škola ili fakultet"
          value={myState.zavrsena}
        />

        <Opcije>
          <h4>Plaćanje školarine</h4>
          <Opcija onClick={() => setMyOpcija(0)}>
            <Round filled={myOpcija === 0} />{" "}
            <p>
              Ceo iznos odjednom <span>(10% popusta)</span>
            </p>
          </Opcija>
          <Opcija onClick={() => setMyOpcija(1)}>
            <Round filled={myOpcija === 1} /> <p>U ratama</p>
          </Opcija>
          <Opcija onClick={() => setMyOpcija(2)}>
            <Round filled={myOpcija === 2} /> <p>Svaki ispit pojedinačno</p>
          </Opcija>
        </Opcije>

        <input
          onChange={handleChange}
          id="referenca"
          type="text"
          placeholder="Za Fakultet za organsku poljoprivredu sam prvi put čuo/la od"
          value={myState.referenca}
        />

        <DatumPrijave>dana {formatedDate}</DatumPrijave>

        <ButtonWrapper>
          <Button
            onClick={() => {
              sendMassage = {
                ...tempMassage,
                message: `<br/>
              Ime: ${myState.name} <br/>
              Prezime: ${myState.lastName} <br/>
              Adresa: ${myState.adresa} <br/>
              Telefon: ${myState.telefon} <br/>
              E-mail: ${myState.eMail} <br/>
              Datum rodjenja: ${myState.datumRodjenja} <br/>
              Zavrsena skola: ${myState.zavrsena} <br/>
              Placanje: ${myState.placanje} <br/>
              Referenca: ${myState.referenca} <br/>
              datum: ${formatedDate} <br/>
              <br/>
              Institut za organsku poljoprivredu <br/>
              `,
              };
              axios({
                method: "post",
                url: "https://picapab.rs/api/contact/index.php",
                crossDomain: true,
                headers: { "content-type": "application/json" },
                data: sendMassage,
              })
                .then((result) => {
                  setMyState({ ...initState, mailSent: true });
                  console.log("done", result, result.data);
                })
                .catch((error) => console.log("error", error));
            }}
          >
            Prijavi se
          </Button>
          <Button onClick={() => setMyState(...initState)}>Poništi</Button>
          {myState.mailSent && <Poslato>Prijava poslata</Poslato>}
        </ButtonWrapper>
      </Wrapper>
    </PageWrapper>
  );
};

export default Upis;
