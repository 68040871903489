import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../style";

const Wrapper = styled(Link)`
  padding: 8px 32px;
  margin: 8px;
  border-radius: 8px;
  background: ${colors.color2};
  border: 2px solid ${colors.color3};
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background: ${colors.color5};
  }

  &:after {
    content: "";
    height: 50px;
    width: 100%;
    position: absolute;
    background: ${colors.color3};
    bottom: 0;
    left: 0;

    transform: translateY(100%);
    transition: 0.2s all;
    transition-delay: 0.05s;
  }

  &:hover:after {
    transform: translateY(96%);
  }

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
`;

const NavButton = ({ text = "link", path = "/" }) => {
  return <Wrapper to={path}>{text}</Wrapper>;
};

export default NavButton;
