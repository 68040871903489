import React from "react";
import styled from "styled-components";

import { colors } from "../../../style";

import MenuButton from "./mobileMenu/MobileMenuButton";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: ${colors.color2};
  color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MobileMenu = ({ handleClick }) => {
  return (
    <Wrapper onClick={handleClick}>
      <MenuButton path="/fakultet" text="Fakultet" />
      <MenuButton path="/upis" text="Upiši se" />
      <MenuButton path="/vise" text="Saznaj više" />
      <MenuButton path="/linkovi" text="Linkovi" />
      <MenuButton path="/nagrade" text="Nagrade" />
      <MenuButton path="/kontakt" text="Kontakt" />
    </Wrapper>
  );
};

export default MobileMenu;
