import React, { useEffect } from "react";

import styled from "styled-components";
import gsap from "gsap";

import { colors, Bold, PageWrapper, HLine } from "../../../style";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: blue;
    text-decoration: underline;
  }
  p {
    margin-top: 0.1rem;
  }
  h2 {
    margin: 2rem 0;
    font-size: 2rem;
    color: ${colors.color2};
  }
  h2:first-of-type {
    margin: 0;
  }
  ol {
    li {
      margin: 20px 0;
      span {
        overflow: hidden;
      }
    }
  }

  input {
    padding: 8px;
    padding-left: 24px;
    margin: 0.4rem 0;
    border: 1px solid ${colors.color3};
    border-radius: 2px;

    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  }

  input::-webkit-input-placeholder {
    opacity: 0.8;
  }
  input:-ms-input-placeholder {
    opacity: 0.8;
  }
  input::placeholder {
    opacity: 0.8;
  }
`;

const Ul = styled.div`
  ul {
    padding-top: 8px;
    li {
      margin: 8px;
      list-style: disc inside none;
    }
  }
`;

const Prijava = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  align-items: center;
  overflow: hidden;
`;

const PlacanjeLi = styled.div`
  grid-column: 1/-1;
`;

const Upis = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  const lineMaker = () => {
    let x = "_";
    for (let i = 0; i < 100; i++) {
      x += "_";
    }
    return x;
  };
  return (
    <PageWrapper>
      <Wrapper ref={(el) => (myRef = el)}>
        <h2>Upis</h2>
        <HLine />
        <ul>
          <li>
            Upis na fakultet je otvoren tokom <Bold>cele godine</Bold> i vrši se
            popunavanjem <Bold>Prijave za upis</Bold> i slanjem podataka na
            zvanični e-mail fakuleta ( <Bold>cns.school@hotmail.com</Bold> ) ili
          </li>

          <li>
            preuzimanjem prijave u pdf formatu sa{" "}
            <a target="_blank" href="./upis.pdf">
              ovog linka.
            </a>
          </li>
        </ul>

        <h2>Prijava za upis</h2>
        <ol>
          <Prijava>
            <li>
              <Bold>Ime i Prezime:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>Kućna Adresa:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>Telefon:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>E mail:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>Datum rođenja:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>Završena srednja škola ili fakultet:</Bold>{" "}
            </li>
            <span>{lineMaker()}</span>
            <PlacanjeLi>
              <li>
                <Bold>Plaćanje školarine:</Bold>
                <Ul>
                  <ul>
                    <li>Ceo iznos odjednom (10% popusta)</li>
                    <li>U ratama</li>
                    <li>Svaku put pojedinacno</li>
                  </ul>
                </Ul>
              </li>
            </PlacanjeLi>
            <li>
              <Bold>Za fakultet sam čuo/la od:</Bold>
            </li>
            <span>{lineMaker()}</span>
            <li>
              <Bold>Datum:</Bold>
            </li>
            <span>{lineMaker()}</span>
          </Prijava>
        </ol>
      </Wrapper>
    </PageWrapper>
  );
};

export default Upis;
