import React from "react";
import styled from "styled-components";

import { colors } from "../../../../style";

const Wrapper = styled.div`
  height: 250px;
  width: 280px;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px lightgrey;
  position: relative;
  margin-top: 3rem;

  a:hover {
    color: ${colors.color3};
  }
  h3 {
    margin-bottom: 8px;
  }
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
`;

const Top = styled.div`
  background: ${colors.color2};
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 16px;
  border-bottom: solid 4px ${colors.color3};
  border-top: solid 4px ${colors.color3};

  h3 {
    font-weight: 600;
  }
`;

const Bottom = styled.div`
  padding: 24px 16px 16px;
  font-weight: 600;
  p {
    margin-bottom: 0.3rem;
  }
`;

const Grey = styled.div`
  position: absolute;
  bottom: 8px;
  right: 24px;
  color: #aaa;
`;

const KontaktBox = (props) => {
  const { children, title, click } = props;
  return (
    <Wrapper>
      <Top>
        <h3>{title}</h3>
      </Top>
      <Bottom>
        {children}
        {click && (
          <Grey>
            <p>click</p>
          </Grey>
        )}
      </Bottom>
    </Wrapper>
  );
};

export default KontaktBox;
