import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../../assets/cornlogo.png";

import { colors } from "../../../style";

const Wrapper = styled(Link)`
  font-size: 1.7rem;
  font-weight: 500;
  padding: 16px 32px;
  font-weight: 300;
  background: ${colors.color2};
  color: #fff;
  border-radius: 8px;
  border: solid 2px ${colors.color3};
  display: flex;
  align-items: center;
  transition: 0.4s ease;

  &:hover {
    background: ${colors.color5};
  }

  img {
    align-self: center;
    height: auto;
    width: 36px;
    margin: 0 16px;
    @media only screen and (min-width: 600px) {
      margin-left: 0;
    }
  }

  p {
    margin-right: 0.5rem;
  }

  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);

  span {
    color: ${colors.color3};
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = () => {
  return (
    <Wrapper to="/">
      <img src={logo} alt="logo" />
      <p>
        <span>I</span>nstitut za
      </p>
      <p>
        <span> O</span>rgansku
      </p>
      <p>
        <span>P</span>
        oljoprivredu
      </p>
    </Wrapper>
  );
};

export default Logo;
