import React, { useEffect } from "react";

import styled from "styled-components";
import gsap from "gsap";

import { colors, HLine, PageWrapper } from "../../../style";

import LinkItem from "./linkovi/LinkItem";

const Wrapper = styled.div`
  color: ${colors.color2};
  h2 {
    margin-top: 3rem;
  }
  p {
    margin-top: 0;
  }
`;
const Bold = styled.span`
  font-weight: 600;
  display: inline;

  @media only screen and (max-width: 720px) {
    display: block;
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

const LinkList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 32px;

  @media only screen and (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

const Info = styled.div`
  margin-top: 2rem;
  background: #eee;
  padding: 16px 32px;
  padding-right: 16px;
  line-height: 1.4;
`;

const Linkovi = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  const videoList = [
    {
      display: "Terase i visoke leje, Sep Holcer",
      url: "https://vimeo.com/59728464",
      site: "www.vimeo.com",
    },
    {
      display: "Čudesna farma - permakultura",
      url: "http://vimeo.com/78527630",
      site: "www.vimeo.com",
    },
  ];
  const knjigeList = [
    {
      display: "Organska bašta - velika enciklopedija baštovanstva",
      url:
        "https://www.ivantic.info/Ostale_knjiige/Zdravlje/organska_basta.pdf",
      site: "www.ivantic.info",
    },
    {
      display: "Permakultura, Sep Holcer",
      url: "http://www.mediafire.com/?69l76za3bbuw0n2",
      site: "www.mediafire.com",
    },
  ];
  const linksList = [
    {
      display: "Semena starih sorti",
      url: "http://www.rareseeds.com/",
      site: "www.rareseeds.com",
    },
    {
      display: "Stara semena",
      url: "http://www.staresortesemena.com/",
      site: "www.staresortesemena.com",
    },
    {
      display: "Fakultet za prirodnu medicinu",
      url: "http://institutpm.com/",
      site: "institutpm.com",
    },
    {
      display: "Prirodno-veterinarski fakultet",
      url: "http://institutop.com/pvi/index.html",
      site: "institutop.com",
    },
  ];
  return (
    <PageWrapper>
      <Wrapper ref={(el) => (myRef = el)}>
        <h2>Linkovi</h2>
        <h2>Knjige</h2>
        <HLine />
        <LinkList>
          {knjigeList.map((aVideo, index) => (
            <LinkItem video={aVideo} key={index} />
          ))}
        </LinkList>
        <Info>
          <p>
            Knjiga "Poljoprivreda bez pesticida" dr Aleksandra Bulovića može se
            nabaviti preko:
          </p>
          <br />
          <p>
            tel.<Bold> +381-63-732-7738</Bold>
          </p>
          <p>
            e-mail:<Bold> kontakt@ipmproizvodi.com</Bold>{" "}
          </p>
        </Info>
        <h2>Video</h2>
        <HLine />
        <LinkList>
          {videoList.map((aVideo, index) => (
            <LinkItem video={aVideo} key={index} />
          ))}
        </LinkList>
        <h2>Internet stranice</h2>
        <HLine />
        <LinkList>
          {linksList.map((aVideo, index) => (
            <LinkItem video={aVideo} key={index} />
          ))}
        </LinkList>
      </Wrapper>
    </PageWrapper>
  );
};

export default Linkovi;
