import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #333;
  color: #ccc;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
`;

const Footer = () => {
  const date = new Date();
  return <Wrapper>Copyright © {date.getFullYear()}</Wrapper>;
};

export default Footer;
