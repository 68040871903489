import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { colors } from "../../../../style";

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  border-bottom: solid 3px ${colors.color3};
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  z-index: 1000;

  &:first-of-type {
    border-top: solid 3px ${colors.color3};
  }
`;

const MobileMenuButton = ({ path, text }) => {
  const { push } = useHistory();

  return (
    <Wrapper
      onClick={() => {
        push(path);
      }}
    >
      {text}
    </Wrapper>
  );
};

export default MobileMenuButton;
