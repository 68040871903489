import React from "react";
import { Route, Switch } from "react-router-dom";

import Navbar from "./navbar/Navbar";
import Home from "./pages/Home";
import Upis from "./pages/Upis";
import UpisSimple from "./pages/UpisSimple";
import Vise from "./pages/Vise";
import Linkovi from "./pages/Linkovi";
import Kontakt from "./pages/Kontakt";
import Fakultet from "./pages/Fakultet";
import Nagrade from "./pages/Nagrade";
import Footer from "./Footer";

const Dekstop = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/upis" component={UpisSimple} />
        <Route exact path="/nagrade" component={Nagrade} />
        <Route exact path="/vise" component={Vise} />
        <Route exact path="/linkovi" component={Linkovi} />
        <Route exact path="/kontakt" component={Kontakt} />
        <Route exact path="/fakultet" component={Fakultet} />
      </Switch>
      <Footer />
    </>
  );
};

export default Dekstop;
