import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";

import { PageWrapper, HLine, colors } from "../../../style";

const Wrapper = styled.div`
  p {
    margin-top: 2rem;
  }
  h2 {
    font-size: 2rem;
    color: ${colors.color2};
  }
`;

const Home = () => {
  let myRef = "";

  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);
  return (
    <PageWrapper>
      <Wrapper ref={(el) => (myRef = el)}>
        <h2>Institut za organsku poljoprovredu</h2>
        <HLine />
        <p>
          Institut za organsku poljoprivredu (IOP) je deo The Center for Natural
          Studies (CNS) sa sedištem u Sjedinjenim Američkim Džavama. Naša namera
          je da obezbedimo obrazovanje i druge usluge koje su u skladu sa
          uspostavljenim prirodnim zakonima zdravlja. Verujemo da će ponuđeni
          program omogućiti zainteresovanim osobama da se upoznaju i ovladaju
          principima i tehnikama koji podstiču razvoj proizvodnje zdrave hrane.
          Naš plan je da stvorimo stalno rastuću mrežu zdravstvenih radnika i
          saradnika koji će obezbediti najbolje moguće obrazovanje
          zainteresovanim osobama.
        </p>

        <p>
          The Center for Natural Studies je osnovan 2004. godine u Sakramentu,
          Kalifornija, SAD, a krajem 2011. započeta je realizacija otvaranja
          IOP-a. Kako je na početku 21. veka došlo do eksplozije interesovanja
          za proizvodnju organske hrane, tako da je osnivanje IOP-a nastalo kao
          odgovor na to veliko interesovanje.
        </p>

        <p>
          Odabrani su kvalitetni edukacioni materijali i metode, u saradnji sa
          najboljim svetskim stručnjacima iz oblasti organske poljoprivrede, a
          sve sa ciljem kvalitetnog izlaska u susret potrebama naših studenata i
          zainteresovanih osoba. CNS, sa svojim predstavništvima u skoro svim
          državama bivše Jugoslavije, postao je jedna od vodećih institucija na
          prostorima Balkana u promociji zdravlja i kvalitetnog načina života.
          Na desetine knjiga i video materijala najboljih svetskih stručnjaka iz
          medicine, objavljeno je na našem jeziku, a spisak kvalitetnih izdanja
          se stalno nadopunjuje u skladu sa novim otkrićima iz oblasti zdravlja.
        </p>

        <p>
          U skladu sa tehnološkim napretkom koje prati savremeno doba, naši
          napori su usmereni na proširivanju metoda za što kvalitetnije
          obrazovanje i omogućavanje pristupa najvažnijim informacijama iz
          domena vezanim za organsku poljoprivredu. Naša posvećenost umetnosti
          zdravog življenja i organske poljoprivrede čini nas osposobljenim da
          spremno izađemo u susret najvišim izazovima koje pred nas postavlja
          savremeno bavljenje organskom poljoprivredom na polju edukacije i
          pružanja usluga.
        </p>
      </Wrapper>
    </PageWrapper>
  );
};

export default Home;
