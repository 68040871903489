import React, { useEffect } from "react";

import gsap from "gsap";

import { PageWrapper, HLine } from "../../../style";

const Vise = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  return (
    <PageWrapper>
      <div ref={(el) => (myRef = el)}>
        <h2>Akreditacija</h2>
        <HLine />
        <p>
          Diploma sa ovog fakulteta priznata je u svim državama sveta za rad u
          privatnoj praksi. Priznavanje diplome u državnim institucijama i
          drugim organizacijama koje se bave proizvodnjom hrane, zavisi od
          lokalnih zakona. Napomenimo da je cilj našeg instituta da ponudi
          visoko-kvaliteteno obrazovanje na polju organske poljoprivrede koje je
          naučno utemeljeno, lako dostupno i u praksi potvrđeno, kao i
          omogućavanje studentu da nakon završetka studija započne privatan
          biznis.
        </p>
        <h2>Mogućnosti</h2>
        <HLine />
        <p>
          U okviru našeg fakulteta nudimo veliku mogućnost sticanja
          sveobuhvatnog znanja vezanog za domen teoretskih i praktičnih aspekata
          organske poljoprivrede i prirodnih metoda proizvodnje zdrave hrane,
          koji se mogu primeniti u praktičnom životu ili na poziv javnosti,
          pacijenata, porodice ili prijatelja.
        </p>
        <p>
          Nakon završenih studija, student će biti osposobljen da radi na nekom
          od sledećih područja:
        </p>
        <ul>
          <li>
            Diplomirani inženjer organske poljoprivrede sa privatnom praksom
          </li>
          <li>Savetnik za organsku proizvodnju hrane ili konsultant</li>
          <li>
            Asistent organske poljoprivrede na nekoj od farmi za proizvodnju
            organske hrane
          </li>
          <li>Autor tekstova o organskoj poljoprivredi</li>
          <li>Distributer prirodnih proizvoda organske hrane</li>
          <li>Porodični konsultant organske poljoprivrede</li>
          <li>
            Predavač ili vodič po različitim pitanjima organske poljoprivrede
          </li>
          <li>Vlasnik farme za proizvodnju zdrave hrane</li>
          <li>Vlasnik škole za organsku poljoprivredu</li>
          <li>
            Specijalista za promociju proizvoda na bazi organske poljoprivrede
          </li>
        </ul>
        <p>
          Institut za organsku poljoprivredu nudi svojim studentima besplatnu
          pravnu pomoć za otvaranje privatnog biznisa, kao i besplatnu pomoć oko
          marketinga, uključujući i izradu veb sajta.
        </p>
        <h2>Kolegijum</h2>
        <HLine />
        <p />
        <ul>
          <li>Dr Miroljub Petrović, direktor</li>
          <li>Stefan Malešević, sekretar</li>
          <br />
          <li>Dr Zoran Ristanović</li>
          <li>Dr Aleksandar Bulović</li>
          <li>Mr Vladan Životić</li>
          <li>Mr Mladen Bebić</li>
          <li>Dr Saša Ivanovic</li>
          <li>Dr Slavko Zec</li>
          <li>Dr Stevan Fehirov</li>
          <li>Branko Božić</li>
          <li>Nikola Antić</li>
        </ul>
      </div>
    </PageWrapper>
  );
};

export default Vise;
