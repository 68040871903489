import React, { useState } from "react";
import styled from "styled-components";

import { colors } from "../../../../style";

const Wrapper = styled.div`
  h4 {
    margin: 3rem 0;
  }
`;

const TabButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 3rem;
  border-radius: 16px;
  overflow: hidden;

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;

const TabButton = styled.div`
  text-transform: capitalize;
  background: ${(props) => props.color};
  color: #fff;
  padding: 16px 32px;
  border-right: solid 4px ${colors.color3};
  cursor: pointer;
  position: relative;

  transition: all 0.3s ease;
  &:last-of-type {
    border-right: none;
  }
  &:hover {
    background: ${(props) => props.hoverColor};
  }

  &:after {
    content: "";
    height: 50px;
    width: 100%;
    position: absolute;
    background: ${colors.color3};
    bottom: 0;
    left: 0;

    transform: translateY(100%);
    transition: 0.2s all;
    transition-delay: 0.05s;
  }

  &:hover:after {
    transform: translateY(88%);
  }

  @media only screen and (max-width: 850px) {
    border-right: none;
  }
`;

const Title = styled.div`
  background: ${colors.color2};
  color: #fff;
  padding: 1rem;
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${colors.color3} solid 4px;
  font-size: 1.5rem;
`;

const BS = () => {
  return (
    <div>
      <p>
        Ovaj program predstavlja sveobuhvatni pristup najvažnijim oblastima
        konvencionalne i organske poljoprivrede. Program obuhvata teoretsku
        obuku, kao i praktičnu obuku na nekoj od farmi organske poljoprivrede.
      </p>
      <h4>Ispiti za program B.S.</h4>
      <ul>
        <li>IOP 01 - Meteorologija sa klimatologijom</li>
        <li>IOP 02 - Agrometeorologija</li>
        <li>IOP 03 - Agrohemija sa fiziologijom biljaka</li>
        <li>IOP 04 - Pedologija</li>
        <li>IOP 05 - Semenarstvo</li>
        <li>IOP 06 - Rasadničarstvo</li>
        <li>IOP 07 - Ishrana biljaka</li>
        <li>IOP 08 - Korov i njegovo suzbijanje</li>
        <li>IOP 09 - Zdravlje biljaka</li>
        <li>IOP 10 - Organska proizvodnja voća</li>
        <li>IOP 11 - Organska proizvodnja povrća</li>
        <li>IOP 12 - Probemi sa biljkama</li>
        <li>IOP 13 - Zagađivanje i zaštita tla</li>
        <li>IOP 14 - Permakultura</li>
        <li>IOP 15 - Problemi GMO</li>
        <li>IOP 16 - Ispitivanje tla, vode i vazduha</li>
        <li>IOP 17 - Ispitivanje namirnica</li>
        <li>IOP 18 - Principi prirodne medicine</li>
        <li>IOP 19 - Osnovi religije</li>
        <li>IOP 20 - Praktična nastava</li>
      </ul>
    </div>
  );
};

const MS = () => {
  return (
    <div>
      <p>
        Uslov za pohađanje ovog programa studija jesu završene osnovne studije
        (B.S.), na ovom ili nekom drugom srodnom fakultetu. Program obuhvata
        unapređenje znanja stečenog na osnovnim studijama.
      </p>
      <h4>Ispiti za program M.S.</h4>
      <ul>
        <li>IOP 21 - Biodiverzitet i očuvanje genofonda prirodnih resursa</li>
        <li>IOP 22 - Ishrana domaćih životinja</li>
        <li>IOP 23 - Oplemenjivanje domaćih životinja</li>
        <li>IOP 24 - Zoohigijena i zdravlje životinja</li>
        <li>IOP 25 - Konzervacija zemljišta</li>
        <li>IOP 26 - Ekofiziologija akvakulture</li>
        <li>IOP 27 - Mikrobiologija zemljišta</li>
        <li>IOP 28 - Oplemenjivanje biljnih vrsta</li>
        <li>IOP 29 - Proizvodnja povrća u zaštićenom prostoru</li>
        <li>IOP 30 - Preduzetništvo u agrobiznisu</li>
        <li>IOP 50 - Magistarski rad</li>
      </ul>
    </div>
  );
};

const PhD = () => {
  return (
    <div>
      <p>
        Uslov za pohađanje ovog programa studija jesu završene magistarske
        studije (M.S.), na ovom ili nekom drugom srodnom fakultetu. Program
        obuhvata unapređenje znanja stečenog na osnovnim i magistarskim
        studijama.
      </p>
      <h4>Ispiti za program Ph.D.</h4>
      <ul>
        <li>IOP 31 - Gajenje začinskog i lekovitog bilja</li>
        <li>IOP 32 - Primena mikroorganizama u organskoj poljoprivredi</li>
        <li>IOP 33 - Osnovi fitomedicine</li>
        <li>IOP 34 - Etologija i dobrobit životinja</li>
        <li>IOP 35 - Osnove čuvanja povrća</li>
        <li>IOP 36 - Štetne materije u lancu ishrane životinja</li>
        <li>IOP 37 - Upravljanje poljoprivrednim otpadom</li>
        <li>
          IOP 38 - Obnovljivi izvori bioenergije u agroekološkim sistemima
          poljoprivrede
        </li>
        <li>IOP 39 - Korišćenje geotermalne energije u poljoprivredi</li>
        <li>IOP 40 - Agrogenetički resursi i njihovo čuvanje</li>
        <li>IOP 60 - Doktorski rad</li>
      </ul>
    </div>
  );
};

const Ispiti = () => {
  const [current, setCurrent] = useState(0);
  return (
    <Wrapper>
      <TabButtons>
        <Title>Ispiti za program</Title>
        <TabButton
          onClick={() => setCurrent(0)}
          color={current === 0 ? colors.color4 : colors.color2}
          hoverColor={colors.color5}
        >
          Diplomirani Inženjer
          <br /> organske poljoprivrede (B.S.)
        </TabButton>
        <TabButton
          onClick={() => setCurrent(1)}
          color={current === 1 ? colors.color4 : colors.color2}
          hoverColor={colors.color5}
        >
          Magistar organske
          <br /> poljoprivrede (M.S.)
        </TabButton>
        <TabButton
          onClick={() => setCurrent(2)}
          color={current === 2 ? colors.color4 : colors.color2}
          hoverColor={colors.color5}
        >
          Dokror organske
          <br /> poljoprivrede (Ph.D.)
        </TabButton>
      </TabButtons>
      {current === 0 && <BS />}
      {current === 1 && <MS />}
      {current === 2 && <PhD />}
    </Wrapper>
  );
};

export default Ispiti;
