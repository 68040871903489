import React, { useState } from "react";
import styled from "styled-components";

import MobileMenu from "./MobileMenu";
import NavButton from "./NavButton";
import MenuButton from "./MenuButton";
import Logo from "./Logo";

import corn from "../../../assets/corn.jpg";
import { colors } from "../../../style";

const Wrapper = styled.div`
  height: 300px;
  background: url(${corn}) no-repeat center/cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(50, 50, 50, 0.3);
`;

const InsideWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #333;
  background: ${colors.color2};
  @media only screen and (max-width: 920px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  background: ${colors.color2};
  position: relative;
  width: 100%;
  display: none;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (max-width: 920px) {
    display: flex;
  }
`;

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(0);
  return (
    <Wrapper>
      <MobileWrapper>
        <MenuButton
          currentShow={mobileMenu}
          handleClick={setMobileMenu}
          text="Meni"
        />
        {mobileMenu === 1 && (
          <MobileMenu handleClick={() => setMobileMenu(0)} />
        )}
      </MobileWrapper>
      <TopWrapper>
        <Logo />
      </TopWrapper>
      <InsideWrapper>
        <NavButton path="/fakultet" text="Fakultet" />
        <NavButton path="/upis" text="Upiši se" />
        <NavButton path="/vise" text="Saznaj više" />
        <NavButton path="/linkovi" text="Linkovi" />
        <NavButton path="/nagrade" text="Nagrade" />
        <NavButton path="/kontakt" text="Kontakt" />
      </InsideWrapper>
    </Wrapper>
  );
};

export default Navbar;
