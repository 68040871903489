import React, { useEffect } from "react";

import styled from "styled-components";
import gsap from "gsap";

import { PageWrapper, HLine, Button, Bold } from "../../../style";
import Ispiti from "./fakultet/Ispiti";

const Ul = styled.ol`
  li:first-of-type {
    margin-top: 1rem;
  }
  li {
    list-style: decimal inside none;
  }
`;

const Fakultet = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  return (
    <PageWrapper>
      <div ref={(el) => (myRef = el)}>
        <h2>Fakultet - program studiranja</h2>
        <HLine />
        <p>
          Upis na studije je otvoren tokom cele godine. Studije se odvijaju
          online (dopisnim putem), a nakon položenih ispita student treba da
          provede najmanje mesec dana na nekoj od organskih farmi (u cenu
          školarine je uključen boravak na farmi od mesec dana). Vremenski rok
          studija nije ograničen - zavisi isključivo od studenta - kada položi
          sve ispite i obavi praksu, na kućnu adresu mu se šalje diploma, ili
          može lično da je preuzme u prostorijama fakulteta. Ispiti se polažu
          tako što student za svaki ispit dobija dva Word dokumenta - u jednom
          se nalaze pitanja, a u drugi treba da upiše odgovore. Takođe, za svaki
          ispit dobija se udžbenik u elektronskoj formi (većina njih je već
          postavljena na ovom sajtu), a postoji mogućnost kupovine udžbenika u
          knjižari ili preko nekog od distributera. Dakle, nema predavanje, već
          student sprema ispite isključivo iz udžbenika, a ispite polaže
          popunjavanjem dokumenta sa odgovorima i slanjem na e-mail fakulteta.
        </p>
        <p>Institut za organsku poljoprivredu nudi sledeće programe studija:</p>
        <p>
          <Bold>Diplomirani Inženjer organske poljoprivrede (B.S.)</Bold>
        </p>
        <p>
          <Bold>Magistar organske poljoprivrede (M.S.)</Bold>
        </p>
        <p>
          <Bold>Dokror organske poljoprivrede (Ph.D.)</Bold>
        </p>
        <h2>Školarina</h2>
        <HLine />
        <p>
          Cena školarine za studije{" "}
          <Bold>"Diplomirani inženjer organske poljoprivrede" (B.S.)</Bold>{" "}
          iznosi <Bold>950</Bold> evra, a uplatu je moguće izvršiti u
          <Bold> tri rate</Bold>. Ukoliko se ceo iznos školarine uplaćuje
          odjednom, studentu se odobrava popust od <Bold>10%</Bold> (u tom
          slučaju je iznos školarine <Bold>855 </Bold>
          evra).
        </p>
        <p>
          U slučaju uplaćivanja školarine u ratama, uplata se vrši na sledeći
          način:
        </p>
        <Ul>
          <li>
            prilikom upisa - <Bold>350</Bold> evra
          </li>
          <li>
            nakon polozenih 6 ispita - <Bold>300</Bold> evra
          </li>
          <li>
            nakon polozenih 13 ispita - <Bold>300</Bold> evra
          </li>
        </Ul>
        <p>
          Postoji mogućnost pojedinačnog plaćanja ispita. Cena polaganja jednog
          ispita je <Bold>50</Bold> evra.
        </p>
        <p>
          Cena školarine za studije{" "}
          <Bold>"Magistar organske poljoprivrede" (M.S.)</Bold> iznosi{" "}
          <Bold>1100 </Bold>
          evra, a uplatu je moguće izvršiti u<Bold> tri rate</Bold>. Ukoliko se
          ceo iznos školarine uplaćuje odjednom, studentu se odobrava popust od{" "}
          <Bold>10%</Bold> (u tom slučaju je iznos školarine <Bold>855</Bold>{" "}
          evra).
        </p>
        <p>
          U slučaju uplaćivanja školarine u ratama, uplata se vrši na sledeći
          način:
        </p>
        <Ul>
          <li>
            prilikom upisa - <Bold>400</Bold> evra
          </li>
          <li>
            nakon polozenih 6 ispita - <Bold>350</Bold> evra
          </li>
          <li>
            nakon polozenih 13 ispita - <Bold>350</Bold> evra
          </li>
        </Ul>
        <p>
          Postoji mogućnost pojedinačnog plaćanja ispita. Cena polaganja jednog
          ispita je <Bold>100</Bold> evra.
        </p>
        <p>
          Cena školarine za studije{" "}
          <Bold>"Doktor organske poljoprivrede" (Ph.D.)</Bold> iznosi{" "}
          <Bold>1250</Bold> evra, a uplatu je moguće izvršiti u
          <Bold> tri rate</Bold>. Ukoliko se ceo iznos školarine uplaćuje
          odjednom, studentu se odobrava popust od <Bold>10%</Bold> (u tom
          slučaju je iznos školarine <Bold>1125</Bold> evra).
        </p>
        <p>
          U slučaju uplaćivanja školarine u ratama, uplata se vrši na sledeći
          način:
        </p>
        <Ul>
          <li>
            prilikom upisa - <Bold>450 evra</Bold>
          </li>
          <li>
            nakon polozenih 6 ispita - <Bold>400</Bold> evra
          </li>
          <li>
            nakon polozenih 13 ispita -<Bold> 400</Bold> evra
          </li>
        </Ul>
        <p>
          Postoji mogućnost pojedinačnog plaćanja ispita. Cena polaganja jednog
          ispita je <Bold>120</Bold> evra.
        </p>
        <p>
          U slučaju da nekoliko članova porodice upisuje fakultet, svaki drugi,
          treći itd član porodice plaća <Bold>50%</Bold> školarine.
        </p>
        <Ispiti />
        <Button to="/upis">Upiši se</Button>
      </div>
    </PageWrapper>
  );
};

export default Fakultet;
