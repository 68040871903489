import styled from "styled-components";
import { Link } from "react-router-dom";

export const colors = {
  color1: "#222831",
  color2: "#30475e",
  color3: "#f2a365",
  color4: "#527aa3",
  color5: "#456687",
};

export const Bold = styled.span`
  font-weight: 600;
`;

export const Button = styled(Link)`
  background: ${colors.color3};
  border-radius: 4px;
  padding: 8px 32px;
  margin-top: 80px;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
`;

export const PageWrapper = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: auto;
  padding: 100px;
  background: #fff;

  -webkit-box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.2);

  p {
    margin-top: 2rem;
  }
  ul {
    margin-top: 1rem;
    li {
      list-style: disc outside none;
      margin-left: 1rem;
    }
  }
  h2 {
    margin-top: 4rem;
    color: ${colors.color2};
    font-size: 2rem;
  }
  h2:first-of-type {
    margin-top: 0;
  }

  @media only screen and (max-width: 1350px) {
    width: 100%;
  }
  @media only screen and (max-width: 1100px) {
    padding: 100px 50px;
  }

  @media only screen and (max-width: 600px) {
    padding: 50px 30px;
  }
`;

export const HLine = styled.div`
  width: 100%;
  border-bottom: solid ${colors.color3} 3px;
  margin: 4px 0 40px;
`;
