import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Desktop from "./desktop/Dekstop";
import Mobile from "./mobile/Mobile";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => setWindowWidth(window.innerWidth), []);
  return (
    <Router>
      <Desktop />
    </Router>
  );
};

export default App;
