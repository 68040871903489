import React, { useEffect } from "react";

import styled from "styled-components";
import gsap from "gsap";

import KontaktBox from "./kontakt/KontaktBox";
import { colors, HLine, PageWrapper } from "../../../style";

const FillPage = styled.div`
  min-height: 550px;
  h2 {
    margin-bottom: 0.2rem;
    color: ${colors.color2};
    font-size: 2rem;
  }
  p {
    margin-top: 0;
  }
`;

const KontaktBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 64px;

  @media only screen and (max-width: 980px) {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

const Kontakt = () => {
  let myRef = "";
  useEffect(() => {
    gsap.from(myRef, { opacity: 0, y: 16 });
  }, []);

  return (
    <PageWrapper>
      <FillPage ref={(el) => (myRef = el)}>
        <h2>Kontakt</h2>
        <HLine />
        <p>
          Kontakt sa Institutom za organsku poljoprivredu može se uspostaviti:
        </p>
        <KontaktBoxes>
          <KontaktBox title="Putem e-maila">
            <p>cns.school@hotmail.com</p>
          </KontaktBox>
          <KontaktBox click title="Putem telefona">
            <p>
              <a href="tel:+17603022066">USA: 1-760-302-2066 </a>
            </p>
            <p>
              <a href="tel:+381621622241">Srbija: +381-62-162-2241</a>
            </p>
          </KontaktBox>
          <KontaktBox title="Pismom na adresu">
            <p>The Center for Natural Studies</p>
            <p>8786 116th street # A 11</p>
            <p>Richmond Hill, New York 11418</p>
            <p>U.S.A.</p>
          </KontaktBox>
        </KontaktBoxes>
      </FillPage>
    </PageWrapper>
  );
};

export default Kontakt;
