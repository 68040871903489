import React from "react";
import styled from "styled-components";

import { colors } from "../../../../style";

const Wrapper = styled.a`
  width: 100%;
  border-radius: 3px;
  padding: 24px;
  font-weight: 600;
  background: ${colors.color2};
  color: #fff;
  border-bottom: solid 4px ${colors.color3};
  position: relative;
  transition: 0.3s ease;

  &:hover {
    background: ${colors.color5};
  }

  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.4);
`;

const FloatingText = styled.p`
  position: absolute;
  bottom: 0;
  right: 20px;
  opacity: 0.5;
`;

const LinkItem = ({
  video = { display: "no text", url: "www.google.com", site: "" },
}) => {
  return (
    <Wrapper target="_blank" href={video.url}>
      <p>{video.display}</p>
      <FloatingText>{video.site}</FloatingText>
    </Wrapper>
  );
};

export default LinkItem;
